import React from 'react';
import './App.css';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'universal-cookie';

export class App extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const token = this.getParameterByName('token');
    const cookies = new Cookies();
    if (token != null) {
      cookies.set('token', token, { path: '/' });
    } else {
      toast.error(`No token found`);
    }

    axios.get("https://www.mlveda.com/ShopifyApps/SampleJWT/shop", {withCredentials: true})
      .then((shop) => {
        console.log("shop data", shop.data);
        toast.success(`shop name: ${shop.data.shop}`);
      })
      .catch(error => {
        console.log("shop error", error);
        toast.error(`error: ${error}`);
      })
  }

  getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <h1>Welcome to JWT test project</h1>
        </header>
        <ToastContainer autoClose={2000} />
      </div>
    );
  }
}

export default App;
